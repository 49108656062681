.app-login-container {
	width: 100%;
	max-width: 100%;
}

.app-login-main-content {
	width: 100%;
	height: 100%;
	border-radius: 0;
	background: rgba( 70, 70, 71, 0.9 );
	overflow: scroll;
}

.app-logo-content {
	width: 40%;
	background: #fff !important;
	order: 0;
	box-shadow: 0px 0px 10px 0px #464647;
	padding: 5%;
}

.app-login-content {
	margin: auto;
	width: auto;
}

.app-login-content  * {
	color: #fff;
}

@media (max-width: 800px) {
	.app-logo-content {
		width: 100%;
		background: #fff !important;
		order: 0;
		box-shadow: 0px 0px 10px 0px #464647;
		padding: 0 15%;
	}

	.app-login-content {
		width: 90%;
		padding-top: 0;
		margin-top: 25px;
	}

	.app-login-header {
		font-size: 2.5em !important
	}
}
